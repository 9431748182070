<script>
export default {
  name: 'AppleLogin',
  data() {
    return {
      loadingInProgress: false,
    };
  },
  created() {
    this.receiveDetails();
  },
  methods: {
    receiveDetails() {
      // this.loadingInProgress = true;
      const urlString = new URL(window.location.href);
      const state = urlString.searchParams.get('state') !== null ? urlString.searchParams.get('state') : null;
      const code = urlString.searchParams.get('code') !== null ? urlString.searchParams.get('code') : null;
      const email = urlString.searchParams.get('email') !== null ? urlString.searchParams.get('email') : null;
      const token = urlString.searchParams.get('token') !== null ? urlString.searchParams.get('token') : null;
      if (state === 'true' && code === 'false') {
        this.$cookies.set('token', token);
        this.$cookies.set('isLoggedIn', true);
        this.$router.push('/hotels');
      } else {
        const storeData = JSON.stringify({
          state, code, email, token,
        });
        localStorage.setItem('appleLogin', storeData);
        this.$router.push('/appleAuthorize');
      }
    },
  },
};
</script>
